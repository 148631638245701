import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './DigitalCurrencyDeposit.css';
import axios from 'axios';
import { useLoading } from '../../../contexts/LoadingContext.tsx';

const DigitalCurrencyDeposit = () => {
  const location = useLocation();
  const { digitalCoinId, latestPrice, coinName, coinLogo, chainName } = location.state || {}; // Access passed data
  const [usdPrice, setUsdPrice] = useState<number | null>(null);
  const [rechargeAddress, setRechargeAddress] = useState('');
  const [depositAmount, setDepositAmount] = useState('');
  const [usdAmount, setUsdAmount] = useState<number>(0);
  const [coinPrice, setCoinPrice] = useState<number | null>(latestPrice || 0);

  const { isLoading, setIsLoading } = useLoading();
  const navigate = useNavigate();

  useEffect(() => {
    if (chainName) {
      fetchLivePrice(chainName); // Fetch price if chainName is provided
    }
  }, [chainName]);

  const fetchLivePrice = async (chain: string) => {

    debugger;

    try {
      const response = await axios.get(
        `https://api.allorigins.win/get?url=${encodeURIComponent(
          `https://api.coingecko.com/api/v3/simple/price?ids=${chain}&vs_currencies=usd`
        )}`
      );
      const data = JSON.parse(response.data.contents);
      const price = data[chain.toString().toLowerCase()]?.usd;

      debugger;
      
      setCoinPrice(price || 0);
      setUsdPrice(price || 0); // Set USD price if required for display
    } catch (error) {
      console.error('Error fetching coin price:', error);
    }
  };

  useEffect(() => {
    if (depositAmount && coinPrice) {
      const depositNumber = parseFloat(depositAmount);
      if (!isNaN(depositNumber) && !isNaN(coinPrice)) {
        setUsdAmount(depositNumber * coinPrice); // Convert deposit to USD
      } else {
        setUsdAmount(0);
      }
    }
  }, [depositAmount, coinPrice]);

  const GoToDepositSlipClick = async () => {
    if (!rechargeAddress || !depositAmount) {
      return;
    }

    setIsLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      navigate('/DepositReceipt', { state: {coinName, rechargeAddress, depositAmount, digitalCoinId } });
    } catch (error) {
      console.error('Error during deposit process:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="deposit-container">
      <button className="back-button" onClick={handleBackClick}>
        ←
      </button>
      <h2>Digital Currency Deposit</h2>
      <div className="currency-info">
        <div className="currency">
          <img src={coinLogo} alt={coinName} className="currency-icon" />
          <span>{coinName}</span>
        </div>
        <div className="chain">Chain: {chainName || 'Unknown'}</div>
        <div className="price">
          Price: {coinPrice ? `$${coinPrice.toFixed(2)}` : 'Loading...'}
        </div>
      </div>
      <div className="recharge-addr">
        <label>Recharge Address</label>
        <input
          type="text"
          placeholder="Enter recharge address"
          value={rechargeAddress}
          onChange={(e) => setRechargeAddress(e.target.value)}
        />
      </div>
      <div className="number-of-deposits">
        <label>Number of Deposits</label>
        <input
          type="text"
          placeholder="Please enter the quantity"
          value={depositAmount}
          onChange={(e) => setDepositAmount(e.target.value)}
        />
        <div className="amount">≈ {usdAmount.toFixed(2)} $</div>
      </div>
      <div className="warning">
        <p>Please do not deposit any non-BTC assets, otherwise, it will be irreversible.</p>
      </div>
      <button
        className="recharge-button"
        onClick={GoToDepositSlipClick}
        disabled={isLoading}
      >
        {isLoading ? 'Processing...' : 'Recharge'}
      </button>
    </div>
  );
};

export default DigitalCurrencyDeposit;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faShieldAlt, faMoneyCheckAlt, faEnvelope, faQuestionCircle, faShareAlt, faHeadset, faCog, faArrowLeft, faLock } from '@fortawesome/free-solid-svg-icons';
import './Navigation.css'; // Import the CSS file

interface NavigationMenuProps {
    toggleMenu: () => void;
    isOpen: boolean;
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({ toggleMenu, isOpen }) => {
    // Get the username from localStorage
    const userName = localStorage.getItem('username');
    const customUserId = localStorage.getItem('customUserId');

    return (
        <div className={`navigation-menu ${isOpen ? 'open' : 'closed'}`}>
            <button className="close-menu-btn" onClick={toggleMenu}><FontAwesomeIcon icon={faArrowLeft} className="menu-icon" /></button>
            <div className="profile-section">
                <img src="../SearchImages/profile.png" alt="Profile" className="profile-pic" />
                <div className="profile-info">
                    {/* Display the username if logged in */}
                    <span className="profile-name">{userName ? userName : 'Click To Login'}</span>
                    <span className="profile-welcome">{userName ? `Welcome, ${userName}` : 'Welcome To Login'}</span>
                    <span className="profile-welcome">{customUserId ? `User Id: , ${customUserId}` : ''}</span>
                </div>
            </div>
            <ul className="menu-list">
                <li><a href="#"><FontAwesomeIcon icon={faUserCheck} className="menu-icon" /> Identity authentication</a></li>
                <li><a href="#"><FontAwesomeIcon icon={faShieldAlt} className="menu-icon" /> Security</a></li>
                <li><a href="#"><FontAwesomeIcon icon={faMoneyCheckAlt} className="menu-icon" /> Method Of Withdrawal</a></li>
                <li><a href="#"><FontAwesomeIcon icon={faEnvelope} className="menu-icon" /> Notifies</a></li>
                <li><a href="#"><FontAwesomeIcon icon={faQuestionCircle} className="menu-icon" /> Support</a></li>
                <li><a href="#"><FontAwesomeIcon icon={faShareAlt} className="menu-icon" /> Share The App</a></li>
                <li><a href="#"><FontAwesomeIcon icon={faHeadset} className="menu-icon" /> Service Center</a></li>
                <li><a href="/settings"><FontAwesomeIcon icon={faCog} className="menu-icon" /> General</a></li>
                <li><a href="/change-password"><FontAwesomeIcon icon={faLock} className="menu-icon" /> Change Password</a></li> {/* New item added */}
            </ul>
        </div>
    );
};

export default NavigationMenu;

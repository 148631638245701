import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useLoading } from '../contexts/LoadingContext.tsx';
import { toast } from 'react-toastify';
import { getCountryList, register } from '../Common/SharedApis/Auth.tsx';
import './SignUp.css';
import { useNavigate } from 'react-router-dom';

interface Country {
  countryId: number;
  name: string;
  code: string;
  flag: string;
  active: boolean;
}

const SignUp: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState('phone');
  const [countryRegion, setCountryRegion] = useState<string | ''>('');
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [countries, setCountries] = useState<Country[]>([]);
  const { isLoading, setIsLoading } = useLoading();
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const fetchCountries = async () => {
      setIsLoading(true);
      try {
        const countryList = await getCountryList(0);
        setCountries(countryList);
        setIsLoading(false);
      } catch (error) {
        toast.error('Failed to fetch countries');
        setIsLoading(false);
      }
    };

    fetchCountries();
  }, [setIsLoading]);

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleCountryChange = (selectedOption: any) => {
    const selectedCountryName = selectedOption?.value;
    setCountryRegion(selectedCountryName);

    const selectedCountry = countries.find(
      (country) => country.name === selectedCountryName
    );
    if (selectedCountry) {
      setPhoneCode(selectedCountry.code);
    }
  };

  const validateForm = () => {
    const errors: Record<string, string> = {};
    if (!countryRegion) {
      errors.countryRegion = 'Please select a country/region.';
    }
    if (selectedTab === 'phone' && (!phoneNumber || phoneNumber.trim() === '')) {
      errors.phoneNumber = 'Phone number is required.';
    }
    if (selectedTab === 'email' && (!email || !/\S+@\S+\.\S+/.test(email))) {
      errors.email = 'Valid email address is required.';
    }
    if (!newPassword || newPassword.length < 6) {
      errors.newPassword = 'Password must be at least 6 characters long.';
    }
    if (newPassword !== confirmPassword) {
      errors.confirmPassword = 'Passwords do not match.';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleModify = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!validateForm()) {
      setIsSubmitting(false);
      return;
    }

    const model = {
      Email: email,
      Password: newPassword,
      ConfirmPassword: confirmPassword,
      PhoneNumber: phoneNumber,
      FirstName: '',
      LastName: '',
      CountryId: countries.find((country) => country.name === countryRegion)?.countryId || 0,
    };

    try {
      await register(model);
      toast.success('Registration successful!');
      navigate('/dashboard');
    } catch (error) {
      debugger;
      toast.error('Failed to register. Please try again.'+error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const countryOptions = countries.map((country) => ({
    value: country.name,
    label: (
      <div className="country-option">
        <img src={country.flag} alt={country.name} className="country-flag" />
        {country.name} ({country.code})
      </div>
    ),
  }));

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-header">
        <button className="back-button" onClick={handleBackClick}>
          &larr;
        </button>
      </div>
      <h2 className="forgot-password-title">Register</h2>
      <div className="tab-switch">
        <button
          className={selectedTab === 'phone' ? 'active' : ''}
          onClick={() => handleTabChange('phone')}
        >
          Phone Num
        </button>
        <button
          className={selectedTab === 'email' ? 'active' : ''}
          onClick={() => handleTabChange('email')}
        >
          Email
        </button>
      </div>
      <form className="forgot-password-form" onSubmit={handleModify}>
        <div className="input-group">
          <label>Country/Region</label>
          <Select
            options={countryOptions}
            onChange={handleCountryChange}
            placeholder="Please select a country/region"
            isClearable
            className="country-select"
            classNamePrefix="select"
          />
          {formErrors.countryRegion && <span className="error">{formErrors.countryRegion}</span>}
        </div>
        {selectedTab === 'phone' && (
          <div className="input-group">
            <label>Phone Num</label>
            <div className="phone-input">
              <input
                type="text"
                placeholder="Code"
                className="code-input"
                value={phoneCode}
                readOnly
              />
              <input
                type="text"
                placeholder="Please enter Phone"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            {formErrors.phoneNumber && <span className="error">{formErrors.phoneNumber}</span>}
          </div>
        )}
        {selectedTab === 'email' && (
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              placeholder="Please enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {formErrors.email && <span className="error">{formErrors.email}</span>}
          </div>
        )}
        <div className="input-group">
          <label>New Password</label>
          <input
            type="password"
            placeholder="Please enter New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          {formErrors.newPassword && <span className="error">{formErrors.newPassword}</span>}
        </div>
        <div className="input-group">
          <label>Confirm New Password</label>
          <input
            type="password"
            placeholder="Please enter the new password again"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {formErrors.confirmPassword && <span className="error">{formErrors.confirmPassword}</span>}
        </div>
        <button type="submit" className="modify-button" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Register'}
        </button>
        <div>
          <a
            href="/login"
            className="forgot-password-link"
            style={{ float: 'right', textDecoration: 'none', color: '#007bff' }}
          >
            Already Have an Account?
          </a>
        </div>
      </form>
    </div>
  );
};

export default SignUp;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './DigitalCoins.css';
import { fetchAllDigitalCoinsById } from '../../../Common/SharedApis/DespositMethods.tsx';
import { useLoading } from '../../../contexts/LoadingContext.tsx'; // Import the loading context
import axios from 'axios';

interface DigitalCurrency {
  digitalCoinId: number;
  coinName: string;
  coinLogo: string;
  chainName: string;
  active: boolean;
  entryDateTime: string;
  depositAddress: string;
  depositMethodId: number;
}

const DigitalCoins: React.FC = () => {
  const { depositMethodId } = useParams<{ depositMethodId: string }>();
  const navigate = useNavigate();
  const [digitalCurrencies, setDigitalCurrencies] = useState<DigitalCurrency[]>([]);
  const { isLoading, setIsLoading } = useLoading(); // Get loading context

  useEffect(() => {
    const fetchData = async () => {
      if (depositMethodId) {
        setIsLoading(true); // Set loading state to true
        try {
          const data = await fetchAllDigitalCoinsById(parseInt(depositMethodId));
          if (Array.isArray(data)) {
            setDigitalCurrencies(data);
          } else {
            console.error('Unexpected data format:', data);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsLoading(false); // Set loading state to false
        }
      } else {
        console.error('DepositMethodId is undefined');
        setIsLoading(false); // Set loading state to false
      }
    };

    fetchData();
  }, [depositMethodId, setIsLoading]);

  const handleBackClick = () => {
    navigate(-1);
  };

  

  const GoToDigitalCurrencyDepositClick = async (
    digitalCoinId: number,
    coinName: string,
    coinLogo: string,
    chainName: string
  ) => {
    // Fetch price when the coin is clicked
  
    // Navigate to the deposit page with the price
    navigate(`/DigitalCurrencyDeposit/${digitalCoinId}`, {
      state: {
        digitalCoinId,
        coinName,
        coinLogo,
        chainName,
       
      },
    });
  };

  return (
    <div className="digital-currency-container">
      <div className="digital-currency-header">
        <button className="digital-currency-back-button" onClick={handleBackClick}>←</button>
        <h1 className="digital-currency-title">Digital currency deposit</h1>
      </div>
      <div className="digital-currency-subheader">
        Please fill in the actual deposit amount truthfully, otherwise it may cause the system audit to fail and affect the deposit progress.
      </div>
      <div className="digital-currency-list">
        {digitalCurrencies.length > 0 ? (
          digitalCurrencies.map(currency => (
            <div
              key={currency.digitalCoinId}
              className="digital-currency-detail"
              onClick={() => GoToDigitalCurrencyDepositClick(
                currency.digitalCoinId,
                currency.coinName,
                currency.coinLogo,
                currency.chainName
              )}
            >
              <div className="digital-currency-detail-icon">
                <img
                  src={currency.coinLogo}
                  alt={currency.coinName}
                  className="digital-currency-icon"
                />
              </div>
              <div className="digital-currency-detail-info">
                <p className="coin-name">{currency.coinName}</p>
                <p className="coin-chain">Chain: {currency.chainName}</p>
                <span className="arrow-icon">{'>'}</span>
              </div>
            </div>
          ))
        ) : (
          <p>No digital currencies found.</p>
        )}
      </div>
    </div>
  );
};

export default DigitalCoins;
